import React, { useState, useEffect } from "react";
import { BASE_SITE_URL } from "../shared/constants";
import DataService from "../services/api/data_services";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import Megaview from "../templates/MegaView";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import VerticalLinearStepper from "../templates/VerticalStepper";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/ProductScaling/product-scaling-banner-image.png";
//Medium View
import MediumviewWithHover from "../templates/MediumViewWithHover";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";

//Medium View
import MediumviewImage1 from "../assets/Images/ProductScaling/Understanding-New-User-Requirements.png";
import MediumviewImage2 from "../assets/Images/ProductScaling/User-Engagement-Analysis.png";
import MediumviewImage3 from "../assets/Images/ProductScaling/User-Perspectives-design-thinking.png";
import MediumviewImage4 from "../assets/Images/ProductScaling/Scalable-architecture-Unexpected-Usage.png";

//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";

//Info View
import Infoview from "../templates/InfoView";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";

//Six Hover Cards
import EightPlainCards from "../templates/EightPlainCards";
import HoverCardWithBtnIcon1 from "../assets/Images/ProductScaling/Rapid-Growth.svg";
import HoverCardWithBtnIcon2 from "../assets/Images/ProductScaling/Incorporate-new-technologies.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/ProductScaling/Improve-product-reliability.svg";
import HoverCardWithBtnIcon4 from "../assets/Images/ProductScaling/Speed.svg";
import HoverCardWithBtnIcon5 from "../assets/Images/ProductScaling/Improved-Efficiency.svg";
import HoverCardWithBtnIcon6 from "../assets/Images/ProductScaling/Non-disruptive.svg";
import HoverCardWithBtnIcon7 from "../assets/Images/ProductScaling/Secure-Design.svg";
import HoverCardWithBtnIcon8 from "../assets/Images/ProductScaling/ISO-27001-&-9001.svg";

import ogimage from "../assets/Images/ogImages/OG_Product-scale.png";
const getStepper = () => {
  return [
    "Moving crucial business processes from the monolith application to microservices",
    "Turning legacy system to API based",
    "Helping you prepare for CI/CD (continuous integration and delivery)",
    "Moving development environment to containers for greater efficiency",
    "Moving configuration to production servers Automated testing an Bug fixes",
    "Redesigning Information Architecture and the key elements of the UX",
    "New functionality implementation and deployment",
    "Migration of the legacy systems to more up-to-date modern API based frameworks",
    "Moving product to a cloud solution for more elasticity and scalability",
  ];
};

const ProductScaling = () => {
  const stepper = getStepper();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Product Scaling"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Product Scaling&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Product Scaling&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: "Product Scaling",
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Medium View
   */

  const MediumviewData = {
    title: (
      <div className="ttc">
        Benefit From Our <br /> <span className="red-line3letter">Cap</span>
        abilities
      </div>
    ),
    data: [
      {
        MediumviewImage: MediumviewImage1,
        MediumviewAlt: MediumviewImage1,
        MediumviewHeading:
          "Understanding New User Requirements and Pain Points",
        MediumviewContent: (
          <div className="mt-40">
            At the early stage of the product development process , the data you
            collect is from repeat tests and early adopters. Through the scaling
            process, we are able to understand how different users experience
            the product. Not many users are able to communicate effectively
            about it’s pain points of the product and, therefore, we refer them
            to a business analyst who analyses user requirements, carefully
            constructs the case and documents a set of high quality system
            requirements to ensure that the requirements meet certain quality
            characteristics. This leads to more input, more suggestions to help
            build up the features for your new users.
          </div>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage2,
        MediumviewAlt: MediumviewImage2,
        MediumviewHeading: "User Engagement Analysis",
        MediumviewContent: (
          <div className="mt-40">
            It is very crucial to understand your users better by analyzing
            their behavior and tracking their engagement. You can gather
            essential data about your product usage including features that are
            most popular, and those which are mostly avoided. You can also
            understand about any process that your user finds difficult to deal
            with. Tracking these important data and asking user feedback can
            effectively establish what changes would benefit and increase user
            experience.
          </div>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage3,
        MediumviewAlt: MediumviewImage3,
        MediumviewHeading: "User Perspectives Embracing Design Thinking",
        MediumviewContent: (
          <div className="mt-40">
            It’s not exaggerated to say that mobile apps are not just products
            of modern information technology, but rather a lifestyle that users
            choose to follow. We believe that users will always find issues with
            your product, and often issues that you wouldn’t think were a
            problem. Due to the crucial role mobile apps play, we at Digiryte
            understand our responsibility to design apps from the user’s
            perspective and to the user’s convenience.
          </div>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage4,
        MediumviewAlt: MediumviewImage4,
        MediumviewHeading: "Scalable Architecture to Handle Unexpected Usage",
        MediumviewContent: (
          <div className="mt-40">
            Every single user has a different way of using your product. Not
            everyone will use the product the way you want it to be used. Some
            users may not use the core features, but prefer to opt for some
            other non-essential features that may be widely popular. The way
            users interact and engage with your product on a daily basis will
            help you understand how to make your product perfect for your users.
            At Digiryte, we’re constantly working on improving your product to
            ensure that users get a better grip of it.
          </div>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
    ],
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        Are You Ready To Scale Your <br />
        <span className="red-line3letter">Pro</span>duct?
      </div>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        In order to scale up your business, one must not only respond to user
        feedback and suggestions but also anticipate user needs in order to
        increase market share. Scaling involves a number of small but
        significant changes that have been added to the product feature in order
        to thrive in an ever-evolving market.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Six Hover Card
   */

  const EightHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Rapid Growth",
    HoverCardWithBtntitle2: "Incorporate new technologies",
    HoverCardWithBtntitle3: "Improve product reliability",
    HoverCardWithBtntitle4: "Speed",
    HoverCardWithBtntitle5: "Improved Efficiency",
    HoverCardWithBtntitle6: "Non-disruptive",
    HoverCardWithBtntitle7: "Secure by Design",
    HoverCardWithBtntitle8: "ISO 27001 & 9001",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnIcon6: HoverCardWithBtnIcon6,
    HoverCardWithBtnIcon7: HoverCardWithBtnIcon7,
    HoverCardWithBtnIcon8: HoverCardWithBtnIcon8,
    HoverCardWithBtnContent1:
      "You must have a process for every role in your business in order to grow your operations. Digiryte helps you define and measure every step of your workflow and allows you to identify problems, progress and delays.",
    HoverCardWithBtnContent2:
      "Scalability is a key driver of business growth. By adopting emerging technologies and constantly innovating, we ensure that our clients stay ahead of competition and have a high level of market visibility.",
    HoverCardWithBtnContent3:
      "A number of decisions are made through the development process that result in reliability. We are creating a reliable product by using the right insights and the ability of existing technology to scale up your dream product.",
    HoverCardWithBtnContent4:
      "One must know when to grow and this can play a key role in the long-term sustainability of your business. We help our customers find the right balance of acceleration to maximise their business and capture market share without breaking the bank.",
    HoverCardWithBtnContent5:
      "The team at Digiryte is committed to help make your product journey be trainable, trackable, and reportable. We ensure we help you understand the best possible use of your resources, decreasing costs, and increasing productivity.",
    HoverCardWithBtnContent6:
      "Small changes to your product means minimum disturbance or difficulty to users, but cumulatively significant.We ensure the user experience is not disturbed but only enhanced with these changes.",
    HoverCardWithBtnContent7:
      "During the scaling process we ensure the product is robust enough to survive and thrive in the market. Our aim is to create an efficient digital product that is secure and serves a large user base.",
    HoverCardWithBtnContent8:
      "We strive to continuously adapt and assure our clients that their products are in line with the latest rules and best practises. All our work is ISO compliant and has the quality and safety of ISO 27001 and 9001 certifications.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    HoverCardWithBtnIconAlt6: "LMS Development ",
    HoverCardWithBtnIconAlt7: "LMS Development ",
    HoverCardWithBtnIconAlt8: "LMS Development ",
    HoverCardIconClass: "mb-10",
    HoverCardWithBtnContentClass: "btncontent mt-10",
    Extracardclassname: "",
    HoverCardExtraiconclassname: "pricing-card-icon-no-bottom",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /***
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    tag: "h3",
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
    tooltip: "yes",
    tip: "TAKE A LOOK",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  return (
    <Layout
      seo={{
        title: "What we do: Product Scaling | Digiryte",
        ogtitle: "What we do: Product Scaling | Digiryte",
        description:
          "Product Scaling involves a number of small but significant changes that adds the product feature in order to thrive in an ever-evolving market.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/product-scaling`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Ben</span>efits of scaling with
          Digiryte
        </h3>{" "}
      </div>
      <div className="mt-50"></div>
      <EightPlainCards SixHoverCardData={EightHoverCardData} />
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Our</span> Scaling Work Includes
        </h3>
        <div className="mt-50 info-view-content">
          Scaling up a business means setting the stage to enable and foster
          growth in your company. It means that you have the ability to grow
          without being hindered. The process requires planning, some funding
          and the right systems, personnel, processes , technology and partners.
        </div>
      </div>
      <div className="body-container">
        {" "}
        <VerticalLinearStepper stepper={stepper} />
      </div>
      <div className="mt-70">
        <MediumviewWithHover
          page="product-scaling"
          MediumviewData={MediumviewData}
        />
      </div>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h2 className="title-header ttc">
              <span className="red-line3letter">Cli</span>ent Stories
            </h2>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              total={clientStoryData.length}
              miniviewData2={miniviewDataCaseStudy}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => {
            setPage(page === 1 ? page + 2 : page + 1);
            setLimit(3);
          }}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="MORE CLIENT STORIES"
            />
          )}
        </div>
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h2>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>{" "}
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default ProductScaling;
